@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'JF-Flat-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('JF-Flat-Regular'), url('./fonts/JF-Flat-Regular.ttf');
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}
.animation-pulse {
  /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 220, 230, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 60px rgba(51, 220, 230, 0);
  }

  100% {
    transform: scale(0.95);
  }
}
.bg-animation {
  position: relative;
  overflow: hidden;
}
.bg-animation:before {
  content: '';
  border-radius: 0.6rem;
  height: 750px;
  width: 789px;
  bottom: 0px;
  top: -323px;
  left: -171px;
  @apply absolute z-0;
  /*  bg-gradient-to-r from-cyan-500 via-teal-500 to-purple-500; */
  background-image: conic-gradient(
    from 170deg,
    cyan,
    white 270deg,
    white,
    rgb(223, 239, 215) 360deg
  );
  animation: rotate-animation 3s linear infinite;
  //background-image: linear-gradient(0deg, #0eb1c3, #407cff, #ec4899, #aaeff2);
}
.btn-animation {
  position: relative;
  overflow: hidden;
}
.btn-animation:before {
  content: '';
  border-radius: 0.6rem;
  height: 138px;
  width: 138px;
  top: -41px;
  left: -5px;
  @apply absolute z-0;
  background-image: conic-gradient(
    from 170deg,
    #262626,
    cyan 270deg,
    cyan,
    rgb(223, 239, 215) 360deg
  );
  animation: rotate-animation 3s linear infinite;
}

@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
@layer base {
  :root {
    --tw-ring-color: '#3ce7fe';
    --color-secondary: 111 114 185;
    /* ... */
    --space: 4px;
    --space-2x: 8px;
    --space-3x: 12px;
    --space-4x: 16px;
    --space-6x: 24px;
    --space-8x: 32px;
    --space-12x: 48px;
    --space-16x: 64px;
    --space-32x: 128px;

    --text-xs: 12px;
    --text-sm: 14px;
    --text-md: 16px;
    --text-lg: 18px;
    --text-xl: 24px;

    --gray: #252729;
    --secondary-color: #8a8f98;
    --sidebar: #0e0e10;
    --brand: #164e63;
    --brand-lighter: #d6ccee;
    --brand-light: #2a7996;
    --brand-dark: #0f3644;
    --gray1: #222222;
    --error: #ea3323;
  }
}

.chat-bubble:before {
  @apply drop-shadow-lg;
  -webkit-mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMycgaGVpZ2h0PSczJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9J2JsYWNrJyBkPSdtIDAgMyBMIDMgMyBMIDMgMCBDIDMgMSAxIDMgMCAzJy8+PC9zdmc+');
  mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMycgaGVpZ2h0PSczJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9J2JsYWNrJyBkPSdtIDAgMyBMIDMgMyBMIDMgMCBDIDMgMSAxIDMgMCAzJy8+PC9zdmc+');
  left: -10px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  transform: rotate(345deg);
  height: 0.75rem;
  width: 0.75rem;
  background-color: white;
  content: '';
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}

@font-face {
  font-family: 'certFont';
  src: url('/app-images/EdwardianScriptITC.woff') format('woff');
}

#EmailZoho {
  all: initial; /* blocking inheritance for all properties */
}
#EmailZoho * {
  all: unset; /* allowing inheritance within #mydiv */
}
.hyperlink {
  @apply text-blue-600 underline;
}
/* @media print { */
.pagebreak {
  page-break-before: always;
  clear: both;
}
/* } */
/* clock style */
:root {
  --main-box-shadow: '0 0px 0px rgba(0, 0, 0, 0.1), 0 0px 0px rgba(0, 0, 0, 0.15)';
  --main-bg: 'transparent';
  --top-bg: 'transparent';
  --clock-bg: 'transparent';
  --clock-wrapper-bg: 'transparent';
  --done-bg-color: 'transparent';
  --done-border-top: 'transparent';
}
.video-avatar__avatar-title {
  display: flex;
  justify-content: center;
}
.plyr__video-embed iframe {
  top: -50% !important;
  height: 200% !important;
}
.gjs-four-color {
  color: #3ae4fa !important;
}

.ltr-grid {
  direction: ltr;
}

.main {
  /*mask: radial-gradient(18px at 18px 50%, #0000 98%, #000) -18.1px 120px;*/

  background-color: #2a2a2a;
  background-image: linear-gradient(
    31deg,
    #2a2a2a 0%,
    #2a2a2a 25%,
    #2a2a2a 50%,
    #2a2a2a 60%,
    #464646 75%,
    #464646 85%,
    #4e4e4e 100%
  );
}

.footer__inner {
  direction: ltr !important;
}

.zm-modal {
  direction: ltr !important;
}
.settings-dialog {
  direction: ltr !important;
}
.gjs-pn-devices-c > .gjs-pn-buttons {
  display: none !important;
}
.gjs-pn-devices-c::before {
  content: '♾️ Infinity Medical Academy Editor';
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
  height: 100%;
}

.embla__slide {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  /* padding-left: 10px; */
}

.mantine-Textarea-input::-webkit-scrollbar {
  display: none;
}

.mantine-RichTextEditor-toolbarGroup {
  @apply flex-row-reverse;
}

.mantine-TextInput-input,
.mantine-NumberInput-input,
.mantine-Textarea-input,
.mantine-Select-input,
.PhoneInputInput,
.mantine-DatePicker-input {
  @apply mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm;
}
.button-focus-ring {
  @apply border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary focus:ring-2;
}

.focus-ring {
  @apply border rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:ring-2;
}

.passToUL > ul {
  @apply list-disc;
}

.passToULIntroEn > ul {
  @apply list-disc;
  @apply list-outside;
  @apply ml-5;
  @apply mt-2;
}

.passToULIntroAr > ul {
  @apply list-disc;
  @apply list-outside;
  @apply mr-5;
  @apply mt-2;
}

.popover-bg {
  background-image: linear-gradient(
    to right,
    rgb(22 78 99 / 80%),
    rgb(60 231 254 / 80%)
  );
}

.popover-transparent {
}

.truncate-rtl {
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
